//座右铭

import React from "react";
// import cuteBoy from "../imagines/cuteboy.png";

function Motto() {
  const poems = [
    "春衣少年当酒歌，起舞四顾以笑和。",
    "除却君身三尺雪，天下谁人配白衣。",
    "少年负壮气，奋烈自有时。",
    "陌上归来少年郎，满身兰麝扑人香。",
    "季子正年少，匹马黑貂裘。",
    "新丰美酒斗十千，咸阳游侠多少年。",
    "俱怀逸兴状思飞，欲上青天揽明月。",
    "仰天大笑出门去，吾辈岂是蓬蒿人。",
    "青春须早为，岂能长少年。",
    "日月纷纷车走坂，少年意气何由挽。",
    "词源倒流三峡水,笔阵独扫千人军。",
    "青山意气峥嵘，似为我归来妩媚生。",
    "横行青海夜带刀，西屠石堡取紫袍。",
    "春日游，杏花吹满头，陌上谁家年少，足风流。",
    "少年恃险若乎地，独倚长剑凌清秋。",
    "鲜衣怒马少年时，能堪那金贼南渡?",
    "天生我材必有用，千金散尽还复来。",
    "看花东陌上，惊动洛阳人。",
    "白马饰金羁，连翩西北驰。",
    "少年意气强不羁，虎胁插翼白日飞。",
    "满堂花醉三千客，一剑霜寒十四州。",
    "少年猎得平原兔，马后横捎意气归。",
    "少年击剑更吹箫，剑气箫心一例消。",
    "长安少年游侠客，夜上戍楼看太白。",
    "少年驰射出幽并，高秋摇落重横行。",
    "少年恃险若平地，独倚长剑凌清秋。",
    "弓背霞明剑照霜，秋风走马出咸阳。",
    "游宴不知厌，杜陵狂少年。",
    "花时轻暖酒，春服薄装绵。",
    "一身转战三千里，一剑曾当百万师。",
    "黄花盖野田，白马少年游。",
    "少年乘勇气，百战过乌孙。",
    "平生一顾念，意气溢三军。",
    "少年意气强不羁，虎胁插翼白日飞。",
    "一身能擘两雕弧，虏骑千重只似无。",
    "少年斗猛气，怒发为君征。",
    "犀渠玉剑良家子，白马金羁侠少年。",
    "一卷旌收千骑虏，万全身出百重围。",
    "五羊城在蜃楼边，墨绶垂腰正少年。",
    "当时年少春衫薄。骑马倚斜桥，满楼红袖招。",
    "满堂花醉三千客，一剑霜寒十四州。",
    "冲天香阵透长安，满城尽带黄金甲。",
    "壮岁旌旗拥万夫，锦襜突骑渡江初。",
    "当时年少春衫薄，骑马倚斜桥，满楼红袖招。",
    "春风得意马蹄疾，一日看尽长安花。",
    "君不见长松卧壑因风霜，时来屹立扶是堂。",
    "休对故人思故国，且将新火试新茶。诗酒趁年华。",
    "相逢意气为君饮，系马高楼垂柳边。",
    "须知少时凌云志，曾许人间第一流。",
    "鲜衣怒马少年时，能堪那金贼南渡？",
    "画凌烟，上甘泉。自古功名属少年。",
    "莫等闲，白了少年头，空悲切。",
    "力拔山兮气盖世，时不利兮骓不逝。",
    "骓不逝兮可奈何，虞兮虞兮奈若何！",
    "豪情还剩文章在，剑气犹存横海东。",
    "千秋万岁名，寂寞身后事。",
    "仰不愧于天，俯不怍于人。",
    "壮志饥餐胡虏肉，笑谈渴饮匈奴血。",
    "怒发冲冠，凭栏处、潇潇雨歇。",
    "抬望眼、仰天长啸，壮怀激烈。",
    "古来圣贤皆寂寞，惟有饮者留其名。",
    "少年易老学难成，一寸光阴不可轻。",
    "碧水东流至此回，壮志未酬何时归。",
    "一曲高歌破敌行，铁马金戈霜雪明。",
    "马蹄疾，剑气寒，一去千里斩将还。",
    "长风破浪会有时，直挂云帆济沧海。",
    "十年磨一剑，霜刃未曾试。",
    "仗剑走天涯，游侠何须定居。",
    "岁月不待人，青春恰自来。",
    "横笛卧听松风寒，古道边，断雁叫西风。",
    "莫愁前路无知己，天下谁人不识君。",
    "身无彩凤双飞翼，心有灵犀一点通。",
    "人生得意须尽欢，莫使金樽空对月。",
    "雁字回时，月满西楼，花自飘零水自流。",
    "问苍茫大地，谁主沉浮？",
    "风雨不改凌云志，振衣濯足展襟怀。",
    "少年应有鸿鹄志，当骑骏马踏平川。",
  ];

  return (
    <>
      <div className="card mb-3 motto unique-width">
        <div className="row g-0">
          {/* <div className="col-md-4">
          </div> */}
          <div className="col-md-12">
            <div className="card-body">
              <h4 className="card-text">
                 <small> 
                  <div className="text-info text-center">
                    {poems[Math.floor(Math.random() * poems.length)]}
                  </div>
                 </small> 
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Motto;
