//冠军数据

import React from "react";
import champion202401 from "../imagines/champion202401.jpg";

function Champions() {
  return (
    <>
      {/* <div className="card unique-width">
        <div className="row g-0">
          <div className="col-md-4">
            <img
              src={champion202401}
              className="img-fluid rounded-start"
              alt="..."
            />
          </div>
          <div className="col-md-8"></div>
          <div className="card-body">
            <h1 className="card-title text-danger">冠军</h1>
            <h2 className="card-text">昊江湖自由搏击争霸赛</h2>
            <h4 className="card-text">
              <small className="text-body-secondary">2024年1月</small>
            </h4>
          </div>
        </div>
      </div> */}

      <div class="container">
        <div class="row unique-width">
            <div class="col-md-4">
            <img
              src={champion202401}
              className="img-fluid rounded-start"
              alt="..."
            />
            </div>
            <div class="col-md-8">
            <div className="card-body">
            <h1 className="card-title text-danger">冠军</h1>
            <h2 className="card-text">昊江湖自由搏击争霸赛</h2>
            <h4 className="card-text">
              <small className="text-body-secondary">2024年1月</small>
            </h4>
          </div>
            </div>
        </div>
    </div>

    </>
  );
}

export default Champions;
