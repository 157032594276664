import "./App.css";

import PersonData from "./components/PersonData";
import Champions from "./components/Champions";
import Motto from "./components/Motto";
import CoverPerson from "./components/CoverPerson";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <CoverPerson />
          <PersonData />
          <Champions />
          <Motto />
        </div>
      </header>
    </div>
  );
}

export default App;
