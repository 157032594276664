//冠军数据

import React from "react";
import handSomeBoy from "../imagines/Beach_Sea_Boy_20240128.jpg";

function CoverPerson() {
  return (
    <>
        <div className="unique-width">
          <img
            src={handSomeBoy}
            className="img-fluid img-thumbnail mx-auto d-block"
            alt="..."
          />
        </div>
    </>
  );
}

export default CoverPerson;
